import { defineStore } from 'pinia';
import axios from 'axios';

export const useGraphThemeAnalysisStore = defineStore('graphThemeAnalysis', {
	state: () => ({
		dataSet: [],
		filter_top: '',
		labelSet: [],
		trends: [],
	}),
	getters: {
		getTableData(state) {
			return state.dataSet;
		},
		getChartSeries(state) {
			return state.dataSet.map(item => {
				return item.volume;
			});
		},
		getChartXAxis(state) {
			return state.dataSet.map(item => {
				return item.label;
			});
		},
		getChartColors(state) {
			return state.dataSet.map(item => {
				return item.color;
			});
		},
	},
	actions: {
		async fetchData(selectedMarket, selectedProject, filterTop) {
			const selected_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));

			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				filter_top: filterTop,
				date_range_start: selected_dates.from,
				date_range_end: selected_dates.to,
			};

			const response = await axios.post('vuejs/thematic_table_data', options, {
				withCredentials: true,
				headers: { 'Content-Type': 'application/json' },
			});

			if (response.data.data.length > 0) {
				this.trends = response.data.data;

				response.data.data.forEach(record => {
					this.labelSet.push({
						color: record['color'],
						name: record['label'],
					});
				});
			}

			this.dataSet = response.data.data;
			this.filter_top = response.data.filter_top;
		},
		async getLabelTrend(marketId, labelId) {
			const response = await axios.post(
				'vuejs/get_label_trend',
				{
					market_id: marketId,
					label_id: labelId,
				},
				{ withCredentials: true },
			);

			if (response.data.success === 'success') {
				this.trends = response.data.data;
			}

			return false;
		},
	},
	persist: false,
});

import { defineStore } from 'pinia';
import axios from 'axios';
import { useNotificationStore } from '@/store/global/notification';
import { useAuthStore } from '@/store/global/auth';

export const useProfileStore = defineStore('profile', {
	state: () => ({
		user_name: 'John Doe',
		user_first_name: 'John',
		user_last_name: 'Doe',
		user_image: 'https://images.unsplash.com/photo-1522441815192-d9f04eb0615c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80',
		fetched: false,
		activity_data: {
			first_name: '',
			last_name: '',
			total: 0,
			active: 0,
			done: 0,
			num_total: 0,
			num_active: 0,
			num_done: 0,
		},
	}),
	actions: {
		async fetchData() {
			const result = await axios.post('vuejs/user_details', { withCredentials: true });
			this.user_name = result.data.data.first_name + ' ' + result.data.data.last_name;
			this.user_first_name = result.data.data.first_name;
			this.user_last_name = result.data.data.last_name;

			if (result?.data?.data?.avatar) {
				this.user_image = result.data.data.avatar; // result.data.data.avatar
			} else {
				this.user_image = `https://ui-avatars.com/api/?name=${this.user_first_name}+${this.user_last_name}&background=random`;
			}

			this.fetched = true;

			// const response = await axios.post('vuejs/user_task_chart', {withCredentials: true});
			axios
				.post('vuejs/user_task_chart', { withCredentials: true })
				.then(response => {
					this.activity_data = response.data.data;
				})
				.catch(error => {
					console.log('error', error);
				});
		},
		async userUploadAvatar(croppedImageDataUrl) {
			const notification = useNotificationStore();
			console.log('userUploadAvatar');
			console.log('croppedImageDataUrl', croppedImageDataUrl);
			const response = await axios.post(
				'vuejs/user_upload_avatar',
				{
					image: croppedImageDataUrl,
				},
				{
					withCredentials: true,
					headers: { 'Content-Type': 'application/json' },
				},
			);
			console.log(response);
			if (response && response.status === 200) {
				this.user_image = response.data.data.avatar_url;
				notification.open('success', 'Your avatar has been updated successfully');
			} else {
				notification.open('error', 'An error occurred while updating your avatar');
			}
			return response.data;
		},
		async userUpdateProfile(data, tMessages) {
			const notification = useNotificationStore();
			const user = useAuthStore();
			user.changeLanguage(data.language);
			const response = await axios.post(
				'vuejs/user_update_profile',
				{
					data: data,
				},
				{
					withCredentials: true,
					headers: { 'Content-Type': 'application/json' },
				},
			);
			if (response && response.status === 200) {
				this.user_first_name = data.firstName;
				this.user_last_name = data.lastName;
				this.user_name = data.firstName + ' ' + data.lastName;

				if (tMessages) {
					notification.open('success', tMessages.success);
				}
			} else {
				if (tMessages) {
					notification.open('error', tMessages.error);
				}
			}
			return response.data;
		},
	},
	persist: true,
});

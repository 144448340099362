<template>
	<aside>
		<router-link active-class="active-link" class="btn btn-primary" to="/" data-cy="sb_logo" id="logo">
			<logo_icon />
		</router-link>
		<div class="navigation">
			<featureSection :feature-name="'dashboard'" :show="false">
				<router-link active-class="active-link" class="btn btn-primary" to="/" data-cy="sb_dashboard_link" id="sb_dashboard_link" v-tooltip="t('dashboard.title')">
					<dashboard_icon />
				</router-link>
			</featureSection>

			<featureSection :feature-name="'tasks'" :show="false">
				<router-link class="btn btn-primary" to="/tasks" data-cy="sb_tasks_link" id="sb_tasks_link" v-tooltip="t('tasks.title')">
					<tasks_icon />
				</router-link>
			</featureSection>

			<featureSection :feature-name="'visibility'" :show="false">
				<router-link class="btn btn-primary" to="/visibility" data-cy="sb_visibility_link" id="sb_visibility_link" v-tooltip="t('visibility.title')">
					<eyes_icon />
				</router-link>
			</featureSection>

			<featureSection :feature-name="'calendar'" :show="false">
				<router-link class="btn btn-primary" to="/calendar" data-cy="sb_calendar_link" id="sb_calendar_link" v-tooltip="t('calendar.title')">
					<calendar_icon />
				</router-link>
			</featureSection>

			<featureSection :feature-name="'finder'" :show="false">
				<router-link class="btn btn-primary" to="/finder" data-cy="sb_finder_link" id="sb_finder_link" v-tooltip="t('finder.keywords_finder')">
					<search_icon />
				</router-link>
			</featureSection>

			<featureSection :feature-name="'technical'" :show="false">
				<router-link class="btn btn-primary" to="/technical" data-cy="sb_settings_link" id="sb_settings_link" v-tooltip="t('technical.title')">
					<!-- <technical_icon /> -->
                    <!-- <img src="@/assets/icon/speedometer.svg" style="width: 1.8rem;"> -->
                    <speedometer_icon />
				</router-link>
			</featureSection>

			<featureSection :feature-name="'users'" :show="false">
				<router-link class="btn btn-primary" to="/users" data-cy="sb_users_link" id="sb_users_link" v-tooltip="t('users')">
					<users_icon />
				</router-link>
			</featureSection>

			<featureSection :feature-name="'clients'" :show="false">
				<router-link class="btn btn-primary" to="/clients" data-cy="sb_clients_link" id="sb_clients_link" v-tooltip="t('clients_admin.title')">
					<clients_icon />
				</router-link>
			</featureSection>

			<!-- <router-link class="btn btn-primary" to="/api" data-cy="sb_api_link" id="sb_api_link" >
                <api_icon/>
            </router-link> -->

			<featureSection :feature-name="'settings'" :show="false">
				<router-link class="btn btn-primary" to="/integration" data-cy="sb_settings_link" id="sb_settings_link" v-tooltip="t('settings.title')">
					<setting_icon />
				</router-link>
			</featureSection>
		</div>
	</aside>
</template>

<script setup>
import { RouterLink } from 'vue-router';
import { useAuthStore } from '@/store/global/auth';
import logo_icon from '../icons/svg/logo_icon.vue';

import featureSection from '@/components/utilities/featureSection.vue';

import api_icon from '../icons/svg/api_icon.vue';
import clients_icon from '../icons/svg/clients_icon.vue';
import calendar_icon from '../icons/svg/calendar_icon.vue';
import dashboard_icon from '../icons/svg/dashboard_icon.vue';
import eyes_icon from '../icons/svg/eyes_icon.vue';
import search_icon from '../icons/svg/search_icon.vue';
import setting_icon from '../icons/svg/setting_icon.vue';
import tasks_icon from '../icons/svg/tasks_icon.vue';
import users_icon from '../icons/svg/users_icon.vue';
import technical_icon from '../icons/svg/technical_icon.vue';
import contentGuidance_icon from '../icons/svg/contentGuidance_icon.vue';
import speedometer_icon from '../icons/svg/speedometer_icon.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const user = useAuthStore();
</script>

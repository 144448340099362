<template>
	<featureSection :feature-name="'dashboard'">
		<main style="margin-bottom: 3rem">
			<div class="title" data-cy="dash_title">
				<h1>{{ t('dashboard.title') }}</h1>
				<p>{{ compared_dates }}</p>
			</div>

			<div class="filter">
				<div>
					<select @change="changePageView" class="dropdown-selected" v-if="user.hasAccess('project manager')" data-cy="dash_select_project" id="dashboard_switch_task_mngt_click">
						<option value="seo_analysis">{{ t('dashboard.SEO_analysis') }}</option>
						<option value="task_management">{{ t('dashboard.team_management') }}</option>
					</select>
				</div>
				<div class="flex justify-content-end" v-if="pageView === 'seo_analysis'">
					<InputSwitch class="mr-3" v-model="ga4WithFilters" @update:modelValue="dashboardStore.filter = !dashboardStore.filter" data-cy="dash_calendar_switch_filter" id="dashboard_include_branding_switch_click">{{ t('dashboard.with_filters') }}</InputSwitch>
					<div class="calendar">
						<calendar data-cy="dash_calendar" id="dashboard_calendar_date_picker_click" />
					</div>
				</div>
				<!-- <Dropdown 
                    v-if="pageView === 'task_management'"
                    class="flex justify-content-end"
                    :selected="userGraphDate"
                    :options="userGraphOptions" 
                    @update:selected="updateUserGraphDate"
                /> -->
			</div>

			<section v-if="pageView === 'seo_analysis'" class="horizontal_cards">
				<suspense>
					<overviewKPIS />
					<template #fallback>
						<overviewKPISkeleton />
					</template>
				</suspense>
			</section>

			<template id="seo_analysis" v-if="pageView === 'seo_analysis'">
				<suspense>
					<graphEvolution />
					<template #fallback>
						<graphEvolutionSkeleton />
					</template>
				</suspense>
				<div class="title" style="margin-top: -1.5rem; padding: 2.5rem 0">
					<h2>{{ t('dashboard.competition_analysis.title') }}</h2>
				</div>
				<suspense>
					<graphCompetitionAnalysis />
					<template #fallback>
						<graphEvolutionSkeleton />
					</template>
				</suspense>
				<suspense>
					<graphStrengthWeakness />
					<template #fallback>
						<graphEvolutionSkeleton />
					</template>
				</suspense>
				<suspense>
					<graphTopComp />
					<template #fallback>
						<graphEvolutionSkeleton />
					</template>
				</suspense>
				<suspense>
					<graphThemeAnalysis />
					<template #fallback>
						<graphEvolutionSkeleton />
					</template>
				</suspense>
			</template>

			<template id="task_management" v-else-if="pageView === 'task_management'">
				<section id="projectManagement">
					<suspense>
						<taskManagement />
						<template #fallback>
							<taskManagementSkeleton />
						</template>
					</suspense>
				</section>
			</template>
		</main>
	</featureSection>
</template>

<script setup>
import { ref, watchEffect, computed, onMounted, onBeforeMount } from 'vue';
import calendar from '../../components/dashboard/calendar.vue';
import overviewKPISkeleton from '../../components/dashboard/overviewKPISkeleton.vue';
import overviewKPIS from '../../components/dashboard/overviewKPIS.vue';
import taskManagementSkeleton from '../../components/visibility/kpis/averagePositionSkeletton.vue';
import taskManagement from '../../components/dashboard/taskManagement.vue';
import graphEvolution from '../../components/dashboard/graphEvolution.vue';
import graphEvolutionSkeleton from '../../components/dashboard/graphEvolutionSkeleton.vue';
import graphCompetitionAnalysis from '../../components/dashboard/graphCompetitionAnalysis.vue';
import graphStrengthWeakness from '../../components/dashboard/graphStrengthWeakness.vue';
import graphStrengthWeaknessSkeleton from '../../components/dashboard/graphStrengthWeaknessSkeleton.vue';
import graphTopComp from '../../components/dashboard/graphTopComp.vue';
import graphThemeAnalysis from '../../components/dashboard/graphThemeAnalysis.vue';
import InputSwitch from '@/components/utilities/forms/InputSwitch.vue';

const profile = useProfileStore();
// i18n
import { useI18n } from 'vue-i18n';

import { onBeforeUnmount } from 'vue';

const { t, locale, availableLocales } = useI18n();

const dashboardStore = useDashboardStore();
let ga4WithFilters = dashboardStore.filter;

let localAuth = localStorage.getItem('auth');

if (!localAuth) {
	window.location.href = '/login';
}

const user = useAuthStore();
const selected_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));

let pageView = ref('seo_analysis');

const changePageView = e => {
	pageView.value = e.target.value;
};
const compared_dates = computed(() => {
	if (selected_dates) {
		const startDate = selected_dates['from'].replaceAll('-', '/');
		const endDate = selected_dates['to'].replaceAll('-', '/');

		return `${t('dashboard.from')} ${startDate} ${t('dashboard.to')} ${endDate}`;
	} else {
		const startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
		const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
		let date_from = startDate.toISOString().substring(0, 10).split('-');
		let date_to = endDate.toISOString().substring(0, 10).split('-');
		return `${t('dashboard.from')} ${date_from[2]}-${date_from[1]}-${date_from[0]} ${t('dashboard.to')} ${date_to[2]}-${date_to[1]}-${date_to[0]}`;
	}
});

const userGraphDate = ref('all');
const userGraphOptions = ref([
	{ name: t('one_week'), value: '1W' },
	{ name: t('one_month'), value: '1M' },
	{ name: t('six_months'), value: '6M' },
	{ name: t('one_year'), value: '1Y' },
	{ name: t('all_dates'), value: 'all' },
]);

const setManagementDate = value => {
	const to = new Date();
	let from = new Date();

	switch (value) {
		case '1W':
			from.setDate(from.getDate() - 7);
			break;
		case '1M':
			from.setMonth(from.getMonth() - 1);
			break;
		case '6M':
			from.setMonth(from.getMonth() - 6);
			break;
		case '1Y':
			from.setFullYear(from.getFullYear() - 1);
			break;
		case 'all':
			from = null;
			break;
		default:
			break;
	}

	localStorage.setItem('dashboardManagementSelectedDates', JSON.stringify({ from: from !== null ? from.toISOString().substring(0, 10) : null, to: to.toISOString().substring(0, 10) }));
};

const updateUserGraphDate = value => {
	userGraphDate.value = value;

	setManagementDate(value);
};

if (!localStorage.getItem('dashboardManagementSelectedDates')) {
	setManagementDate('all');
}

onBeforeMount(async () => {
	let dbLanguage = user.user.language;
	let loginLanguage = localStorage.getItem('language');

	console.log('dbLanguage', dbLanguage);
	console.log('loginLanguage', loginLanguage);

	// HOTFIX TODO: Rework conditions
	// if loginLanguage and dbLanguage are both null, set all to en
	// console.log("SET EN")
	// loginLanguage = 'en';
	// dbLanguage = 'en';
	// user.user.language = 'en';
	// locale.value = 'en';
	// localStorage.setItem('language', 'en');

	// if (dbLanguage === 'null') {
	//   dbLanguage = 'en';
	//   user.user.language = 'en';
	//   locale.value = 'en';
	// }

	// if (loginLanguage === null) {
	//   loginLanguage = dbLanguage;
	//   locale.value = dbLanguage;
	//   localStorage.setItem('language', dbLanguage);
	// } else {
	//   locale.value = loginLanguage;
	// }

	// if (loginLanguage !== dbLanguage) {
	//   await profile.fetchData();
	//   const dataUser = {
	//     'firstName': profile.user_first_name,
	//     'lastName': profile.user_last_name,
	//     'email': user.user.email,
	//     'language': loginLanguage,
	//   };
	//   await profile.userUpdateProfile(dataUser);
	// }
	console.log(availableLocales);
	try {
		if (availableLocales.includes(dbLanguage)) {
			locale.value = dbLanguage;
		} else {
			locale.value = 'en';
		}
	} catch (e) {
		console.log(e);
		locale.value = 'en';
	}
});
</script>

<style lang="scss" scoped>
.title {
	padding-top: 1.5rem;
	width: 100%;
	display: flex;
	gap: 1rem;

	h1 {
		font-size: 1.7rem;
		font-weight: 500;
	}
	h2 {
		margin: 0.7rem 0 0.7rem 0;
		font-size: 1.4rem;
		font-weight: 500;
	}
	p {
		font-size: 0.85rem;
		font-weight: 500;
		font-style: italic;
		color: #919193;
		line-height: 2.3rem;
	}
}

.dropdown-selected {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	background-color: white;
	padding: 10px;
	border: 1px solid;
	border-radius: 4px;
	color: black;
	font-weight: 500;
	font-size: 0.9rem;
}

.filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 1.5rem;
	align-items: center;
	margin: 2.5rem 0 0 0;

	.search {
		display: flex;
		gap: 1rem;
		align-items: center;

		input {
			width: 20rem;
			height: 2rem;
			border: 1px solid #919193;
			border-radius: 0.5rem;
			padding: 0 1rem;
			font-size: 0.85rem;
			font-weight: 500;
			color: #303030;
		}

		button {
			width: 6rem;
			height: 2rem;
			border: 1px solid #919193;
			border-radius: 0.5rem;
			background-color: #fff;
			font-size: 0.85rem;
			font-weight: 500;
			color: #919193;
			cursor: pointer;

			&.yellow {
				background-color: #ffc230;
				color: #000000;

				&:active {
					background-color: #d69706;
					color: #000000;
				}
			}
			&.blue {
				background-color: #d2edf5;
				color: #000000;

				&:active {
					background-color: #a5d9e5;
					color: #000000;
				}
			}

			&:active {
				background-color: #919193;
				color: #fff;
			}
		}
	}
}

.horizontal_cards {
	margin-bottom: 1.5rem;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}

@media screen and (max-width: 1400px) {
	.search {
		margin-top: 3rem;
	}
	.title {
		margin-top: 1rem;
		margin-bottom: -2rem;
	}
	.calendar {
		flex-direction: column;
	}
}

@media screen and (max-width: 1370px) {
	.title {
		margin-top: 1rem;
		margin-bottom: 0rem;
		flex-direction: row;
		display: flex;
		justify-content: space-between;
	}

	.filter[data-v-ed7dad95] {
		display: flex;
		justify-content: space-between;
	}
	.filter {
		flex-direction: row;
		margin: 0.5rem 0 0 0;

		.calendar {
			//display: flex;
			flex-direction: row;
			justify-content: end;
			//max-width: 578px;
			margin: 0 0 0 0;

			//div {
			//  display: flex;
			//  align-items: start;
			//  gap: 0.5rem;
			//  margin-right: 5px;
			//}
		}
	}
	#kpis {
		flex-wrap: wrap;
		& :nth-child(n + 1) {
			span {
				padding-bottom: 1rem;
			}
		}
	}
}
</style>

<template>
	<div class="title">
		<h2>{{ t('dashboard.strength_weaknesses.title') }}</h2>
		<div class="select-label-box">
			<form @submit.prevent="submit" class="flex flex-row align-items-center gap-2">
				<MultiSelect v-model="selectedElement" filter :options="labels" :maxSelectedLabels="maxSelectedLabels" optionLabel="label_name" placeholder="Select labels" class="p-graph-filter" ref="multiSelect">
					<template #option="slotProps">
						<Tag :style="{ backgroundColor: slotProps.option.color }">
							{{ slotProps.option.label_name }}
						</Tag>
					</template>
				</MultiSelect>
				<button style="margin-top: 3px" id="dashboard_strengths_weaknesses_select_labels_click">{{ t('dashboard.strength_weaknesses.select') }}</button>
			</form>
		</div>
	</div>
	<div class="horizontal_cards">
		<div width="50%" style="max-width: 50%" class="card">
			<h2>{{ strengthTitleStart }}</h2>
			<Radar v-if="showGraphStart" id="chart-start" :options="chartOptions" :data="seriesStart" />
			<div v-else>
				<p style="text-align: center">{{ t('no_data') }}</p>
			</div>
		</div>
		<div width="50%" style="max-width: 50%" class="card">
			<h2>{{ strengthTitleEnd }}</h2>
			<Radar v-if="showGraphEnd" id="chart-end" height="400" :options="chartOptions" :data="seriesEnd" />
			<div v-else>
				<p style="text-align: center">{{ t('no_data') }}</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, nextTick, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { Radar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, Filler, PointElement, LineElement, RadialLinearScale } from 'chart.js';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';
import { useMarketStore } from '@/store/global/market';
import { useGraphStrengthWeaknessStore } from '@/store/dashboard/graphStrengthWeakness';

// i18n
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n({});

const marketStore = useMarketStore();
const { selectedMarket, selectedProject } = storeToRefs(marketStore);
const graphStrengthWeaknessStore = useGraphStrengthWeaknessStore();
const { chartSeriesStart, chartSeriesEnd, startDate, endDate, chartLabels, selectedLabels, scaleExtremum } = storeToRefs(graphStrengthWeaknessStore);
const chartOptions = ref();
const seriesStart = ref();
const seriesEnd = ref();
const strengthTitleStart = ref();
const strengthTitleEnd = ref();
const multiSelect = ref(null);
const maxSelectedLabels = ref(4);
const showGraphStart = ref(true);
const showGraphEnd = ref(true);
const labels = ref();
const selectedElement = ref([]);
const selectLabelsInputs = ref([]);

ChartJS.register(Title, Tooltip, Legend, Filler, PointElement, LineElement, RadialLinearScale);

const fetchData = async () => {
	await graphStrengthWeaknessStore.fetchData(selectedMarket.value, selectedProject.value).then(() => {
		strengthTitleStart.value = startDate.value;
		strengthTitleEnd.value = endDate.value;
		seriesStart.value = chartSeriesStart.value;
		seriesEnd.value = chartSeriesEnd.value;
		labels.value = chartLabels.value;
		selectedElement.value = selectedLabels.value;

		if (chartSeriesStart.value.datasets.length === 0) {
			showGraphStart.value = false;
		}

		if (chartSeriesEnd.value.datasets.length === 0) {
			showGraphEnd.value = false;
		}

		chartOptions.value = {
			layout: {
				padding: 0,
			},
			responsive: true,
			legend: {
				labels: {
					fontColor: '#50649c',
					fontSize: 14,
				},
			},
			scales: {
				r: {
					reverse: true,
					min: scaleExtremum.value[0],
					max: scaleExtremum.value[1],
					gridLines: {
						color: '#eaf0f7',
						lineWidth: 2,
					},
					angleLines: {
						color: '#eaf0f7',
						lineWidth: 2,
					},
					pointLabels: {
						fontColor: '#73788b',
						fontSize: 14,
					},
					ticks: {
						fontColor: '#000',
						fontSize: 18,
						fontStyle: 'bold',
						min: 0,
						max: 100,
						stepSize: 10,
					},
				},
			},
		};
	});
};

await fetchData();

const submit = async () => {
	selectLabelsInputs.value = [];

	selectedElement.value.forEach(element => {
		selectLabelsInputs.value.push(element.label_id);
	});

	await graphStrengthWeaknessStore.fetchData(selectedMarket.value, selectedProject.value, selectLabelsInputs.value).then(() => {
		strengthTitleStart.value = startDate.value;
		strengthTitleEnd.value = endDate.value;
		seriesStart.value = chartSeriesStart.value;
		seriesEnd.value = chartSeriesEnd.value;
		labels.value = chartLabels.value;
		scaleExtremum.value = graphStrengthWeaknessStore.scaleExtremum;

		chartOptions.value = {
			layout: {
				padding: 0,
			},
			responsive: true,
			legend: {
				labels: {
					fontColor: '#50649c',
					fontSize: 14,
				},
			},
			scales: {
				r: {
					reverse: true,
					min: scaleExtremum.value[0],
					max: scaleExtremum.value[1],
					gridLines: {
						color: '#eaf0f7',
						lineWidth: 2,
					},
					angleLines: {
						color: '#eaf0f7',
						lineWidth: 2,
					},
					pointLabels: {
						fontColor: '#73788b',
						fontSize: 14,
					},
					ticks: {
						fontColor: '#000',
						fontSize: 18,
						fontStyle: 'bold',
						min: 0,
						max: 100,
						stepSize: 10,
					},
				},
			},
		};

		if (chartSeriesStart.value.datasets.length === 0) {
			showGraphStart.value = false;
		}

		if (chartSeriesEnd.value.datasets.length === 0) {
			showGraphEnd.value = false;
		}
	});
};

const changeMultiselect = () => {
	const selectedItems = selectedElement && selectedElement.value ? selectedElement.value.length : 0;
	if (selectedItems > maxSelectedLabels.value) {
		multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = `${t('item_selected', { count: selectedItems })}`;
	} else if (selectedItems === 0) {
		multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = t('select_labels');
	} else {
		multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = selectedElement.value.map(e => e.label_name).join(', ');
	}
};

watch(selectedElement, () => {
	nextTick(() => {
		changeMultiselect();
	});
});

onMounted(() => {
	changeMultiselect();
});
</script>

<style lang="scss" scoped>
.card {
	background-color: #fff;
	border-radius: 1rem;
	padding: 1.5rem 0.5rem 1rem;
	border: 2px solid #eeede3;
	min-width: 250px;
	flex-grow: 1;
}

h2 {
	margin-bottom: 0.6rem;
}

.horizontal_cards {
	margin-bottom: 1.5rem;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}

.title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-top: 1rem;

	h1 {
		font-size: 1.7rem;
		font-weight: 500;
	}
	h2 {
		margin: 0.7rem 0 0.7rem 0;
		font-size: 1.4rem;
		font-weight: 500;
	}
	p {
		font-size: 0.85rem;
		font-weight: 500;
		font-style: italic;
		color: #919193;
		line-height: 2.3rem;
	}
}

.p-graph-filter {
	float: right;
	margin-bottom: 0.3rem;
}

.select-label-box {
	width: auto;
	display: flex;
	justify-content: space-between;
}
</style>
